import React from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const reports = [
    { id: 'coaccristorey', name: 'Coac Cristo Rey' },
    { id: 'cacpeloja', name: 'Coac Loja' },
    { id: 'coacacciontungurahua', name: 'Coac Acción Tungurahua' },
    { id: 'coacpuellaro', name: 'Coac Puellaro' },
    { id: 'coaccalderon', name: 'Coac Calderón' },
    { id: 'coaceducadoreschimborazo', name: 'Coac Educadores Chimborazo' },
    { id: 'coac14demarzo', name: 'Coac 14 de Marzo' },
    { id: 'coopchone', name: 'Coop Chone' },
    { id: 'coaccredimas', name: 'Coac Credimas' },
    { id: 'cooptena', name: 'Coop Tena' },
    { id: 'coopsierracentro', name: 'Coop Sierra Centro' },
    { id: 'coacvencedores', name: 'Coac Vencedores' },
    { id: 'coaclasnaves', name: 'Coac Las Naves' },
    { id: 'coacsisa', name: 'Coac Sisa' },
    { id: 'coopminga', name: 'Coop Minga' },
    { id: 'coaccrediya', name: 'Coac Crediya' },
    { id: 'coaccrecer', name: 'Coac Crecer' },
    { id: 'coac9octubre', name: 'Coac 9 de Octubre' },
    { id: 'coacantorcha', name: 'Coac Antorcha' },
    { id: 'coacganansol', name: 'Coac Ganansol' },
    { id: 'coacpedromoncayo', name: 'Coac Pedro Moncayo' },
    { id: 'coacsumakkawsay', name: 'Coac Sumak Kawsay' },
    { id: 'coaclorente', name: 'Coac Lorente' },
    { id: 'coacachikinti', name: 'Coac Achikinti' },
    { id: 'cacpezamora', name: 'Cacpe Zamora' },
    { id: 'coacyuyay', name: 'Coac Yuyay' },
    { id: 'coopsac', name: 'Coop Sac' },
    { id: 'coaccalceta', name: 'Coac Calceta' },
  ];

  return (
    <div className="home-container">
      <h1>Selecciona un Reporte</h1>
      <ul className="reports-list">
        {reports.map((report) => (
          <li key={report.id} className="report-item">
            <button
              className="report-button"
              onClick={() => navigate(`/${report.id}`)}
            >
              {report.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Home;
