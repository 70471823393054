import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './PowerBIReport.css';

const reports = {
  coacacciontungurahua: {
    name: 'Coac Acción Tungurahua',
    reportId: '2b69d6ea-7e57-4210-90d5-c978f639ed98',
  },
  cristorey: {
    name: 'Coac Cristo Rey',
    reportId: '1969497f-f21c-4b27-93b0-b2ff9eae34b0',
  },
  cacpeloja: {
    name: 'Coac Loja',
    reportId: '3f9f9445-115b-4a61-84de-af73dcd22726',
  },
  coaceducadoreschimborazo: {
    name: 'Coac Educadores Chimborazo',
    reportId: '5a66492e-169f-449f-8391-dd4289ee151d',
  },
  coac14demarzo: {
    name: 'Coac 14 de Marzo',
    reportId: '750a1878-4724-47f7-a5fd-7f14c1f10323',
  },
  coaccalderon: {
    name: 'Coac Calderón',
    reportId: '2656615e-b7a2-4d0f-866b-3eb09272dace',
  },
  coacpuellaro: {
    name: 'Coac Puellaro',
    reportId: '2a6d3fe5-440d-43e4-a2f8-fb0dc3e1be61',
  },
  coopcredil: {
    name: 'Coop Credil',
    reportId: 'c33b6bd7-fe50-4643-8106-26d8dd60bbab',
  },
  coaccredimas: {
    name: 'Coac Credimas',
    reportId: '5d351e28-aadb-4009-9c22-d72cd64e2ebf',
  },
  cooptena: {
    name: 'Coop Tena',
    reportId: 'd4dec703-8090-469c-a916-dbff9224fef7',
  },
  coopsierracentro: {
    name: 'Coop Sierra Centro',
    reportId: '386965a9-fd11-4b26-90a3-68f22beafcc7',
  },
  coacvencedores: {
    name: 'Coac Vencedores',
    reportId: '6fbae767-4df7-4c32-a752-0aa7157cdb80',
  },
  coaclasnaves: {
    name: 'Coac Las Naves',
    reportId: '376dbb66-e0a4-451a-b448-9632269a8b9d',
  },
  coacsisa: {
    name: 'Coac Sisa',
    reportId: '0b239f29-3c8e-4d5d-a9a3-e1428e9a2998',
  },
  coopminga: {
    name: 'Coop Minga',
    reportId: 'ed3500a0-53e4-4da2-affc-bc0ef10c70e6',
  },
  coac9octubre: {
    name: 'Coac 9 de Octubre',
    reportId: '7eac7ede-21f6-46b0-ba26-16ca0d3c455e',
  },
  coaccrecer: {
    name: 'Coac Crecer',
    reportId: '9fa63eb2-28a8-4bf2-827e-09740cf86f24',
  },
  coaccrediya: {
    name: 'Coac Crediya',
    reportId: '45556dbe-1abc-4bc0-a080-347030f1c1d8',
  },
  coacsierracentro: {
    name: 'Coac Sierra Centro',
    reportId: '386965a9-fd11-4b26-90a3-68f22beafcc7',
  },
  coopchone: {
    name: 'Coop Chone',
    reportId: '194a5b7a-4612-4af5-82be-7d930aa259f3',
  },
  coacantorcha: {
    name: 'Coac Antorcha',
    reportId: 'ebb64eff-487b-4859-b71f-711a46a89b1a',
  },
  coacganansol: {
    name: 'Coac Ganansol',
    reportId: '2e6f0456-3dfb-44a8-9526-ab1fe33359a8',
  },
  coacpedromoncayo: {
    name: 'Coac Pedro Moncayo',
    reportId: '28728804-38a3-43c6-bc4a-07104f668388',
  },
  coacsumakkawsay: {
    name: 'Coac Sumak Kawsay',
    reportId: 'f871cea9-2320-412f-9a68-052b54134fec',
  },
  coaclorente: {
    name: 'Coac Lorente',
    reportId: '31c332e6-0649-4efb-a5fd-513a0f738af4',
  },
  coacachikinti: {
    name: 'Coac Achikinti',
    reportId: 'adac7505-bda2-4bc9-a1dd-b238a8c903d3',
  },
  cacpezamora: {
    name: 'Cacpe Zamora',
    reportId: '24c8f0a8-69a7-4780-8467-68daaa1a50c8',
  },
  coacyuyay: {
    name: 'Coac Yuyay',
    reportId: '6749d222-fafc-41e0-a004-52fc0ef54e5e',
  },
  coopsac: {
    name: 'Coop Sac',
    reportId: 'e1d5c72b-3ea9-4c30-ac6e-cd07a99e9f71',
  },
  coaccalceta: {
    name: 'Coac Calceta',
    reportId: '0902d3bd-aba9-4bc4-a644-cc85c1c3a150',
  },
};

const PowerBIReport = () => {
  const { reportName } = useParams();
  const [embedConfig, setEmbedConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/';
  const groupId = '678cd4dd-ef5e-45ac-8a18-e77af1950ba6'; // Group ID especificado

  const fetchEmbedToken = async (report) => {
    try {
      const response = await fetch(`${BACKEND_URL}api/powerbi/getEmbedToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reportId: report.reportId,
          groupId: groupId,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'No se pudo obtener el embed token');
      }

      const data = await response.json();
      return data.embedToken;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    const initializeEmbedConfig = async () => {
      const report = reports[reportName];

      if (!reportName || !report) {
        setError(`Reporte "${reportName}" no encontrado. Verifica la URL.`);
        setLoading(false);
        return;
      }

      try {
        const embedToken = await fetchEmbedToken(report);

        if (!embedToken) {
          throw new Error('El embed token está vacío.');
        }

        setEmbedConfig({
          type: 'report',
          id: report.reportId,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${report.reportId}&groupId=${groupId}`,
          accessToken: embedToken,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            layoutType: models.LayoutType.Master,
            panes: {
              filters: { visible: false },
            },
            background: models.BackgroundType.Transparent,
            toolbar: { visible: false },
          },
        });
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Error desconocido');
        setLoading(false);
      }
    };

    initializeEmbedConfig();
  }, [reportName, groupId, BACKEND_URL]);

  if (loading) {
    return (
      <div className="loading-container" role="alert" aria-busy="true">
        <div className="spinner"></div>
        <p>Cargando reporte...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container" role="alert">
        <p>Error al cargar el reporte: {error}</p>
      </div>
    );
  }

  return (
    <div className="iframe-container" style={{ position: 'relative', height: '100vh', width: '100%' }}>
      <PowerBIEmbed
        embedConfig={embedConfig}
        cssClassName="report-style-class"
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
};

export default PowerBIReport;
